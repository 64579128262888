<template>
  <div class="fc-wrapper p-grid p-nogutter nested-grid" style="width: 100%">
    <div class="p-col-12 fc-month">
      <div class="p-grid p-nogutter nested-grid" style="width: 100%">
        <div class="p-col-12 fc-header p-p-2 p-text-center p-text-bold">
          {{ _t(months[moment(date).format('M') - 1]) }} {{ moment(date).format('YYYY') }}
        </div>
        <div class="p-col-12">
          <div class="p-d-flex">
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto">{{ _t('label_monday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto">{{ _t('label_tuesday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto">{{ _t('label_wednesday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto">{{ _t('label_thursday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto">{{ _t('label_friday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto fc-weekend">{{ _t('label_saturday_short') }}</span></div>
            <div class="fc-header__cell p-col p-d-inline-flex p-text-bold p-text-center"><span
                class="p-mx-auto fc-weekend">{{ _t('label_sunday_short') }}</span></div>
          </div>
        </div>
        <div class="p-col-12 fc-body" style="position: relative;">
          <div
              v-for="(week, weekIndex) in weeksInMonth"
              :key="weekIndex"
              class="p-d-flex fc-days"
          >
            <template
                v-for="(day, dayIndex) in week.dates"
                :key="dayIndex"
            >
              <div
                  :class="{
                    'fc-weekend': [5,6].includes(parseInt(moment(day).weekday())),
                    'fc-holiday': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, [])),
                    'fc-holiday-inactive': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []) && i.active === false)
                  }"
                  :data-date="moment(day).format('YYYY-MM-DD')"
                  class="fc-body__cell p-col p-d-inline-flex"
                  @click="$emit('clickDay', $event)"
              >
                <span
                    :class="{
                      'fc-current-month': moment(day).month() === moment(date).month(),
                      'fc-prev-month': moment(day).month() < moment(date).month(),
                      'fc-next-month': moment(day).month() > moment(date).month(),
                      'fc-today': moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
                      'fc-holiday': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, [])),
                      'fc-holiday-inactive': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []) && i.active === false)
                    }"
                    :data-date="moment(day).format('YYYY-MM-DD')"
                    class="fc-body__cell-label"
                    @click="$emit('clickDay', $event)"
                >{{ moment(day).format('DD') }}</span>
              </div>
            </template>
          </div>
          <template
              v-for="(event, eventIndex) in eventsInMonth"
              :key="eventIndex"
          >
            <template
                v-for="(time, timeIndex) in event.times"
                :key="timeIndex"
            >
              <div
                  v-tooltip.top="`<small><strong>${event.title}\n</strong>Start: ${moment(time.start).format('YYYY-MM-DD HH:mm')}\nEnd: ${moment(time.end).format('YYYY-MM-DD HH:mm')}</small>`"
                  :data-end="moment(time.end).format('YYYY-MM-DD HH:mm')"
                  :data-id="event.id"
                  :data-start="moment(time.start).format('YYYY-MM-DD HH:mm')"
                  :style="{ backgroundColor: '#689F38' }"
                  class="fc-event p-p-2 p-col"
                  style="max-height: 33px;overflow: hidden;"
                  @click="$emit('clickEvent', $event)"
              >
                {{ event.title }}
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import * as _ from 'lodash';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {DayGridDrawMixin} from '@/components/CustomFullCalendar/mixins/event-draw.mixin';
import {commonMixin} from '@/components/CustomFullCalendar/mixins/common.mixin';

export default {
  name: 'daygrid-month',
  mixins: [commonMixin, DayGridDrawMixin],
  data: () => ({
    viewType: ViewTypeEnum.MONTH,
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    eventsInMonth: [],
    months: [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ],
  }),
  created() {
    this.moment = moment;
    this.console = console;
    this.moment.locale('en', {
      week: {
        dow: 1
      }
    });
    // document.addEventListener('load', this.eventsDraw);
  },
  mounted() {
    this.dayGridDraw();
    document.addEventListener('resize', this.dayGridDraw);
    this.calculateEvents();
  },
  beforeUnmount() {
    // document.removeEventListener('load', this.eventsDraw);
    document.removeEventListener('resize', this.dayGridDraw);
  },
  unmounted() {
    // document.removeEventListener('load', this.eventsDraw);
    document.removeEventListener('resize', this.dayGridDraw);
  },
  methods: {
    calculateEvents() {
      this.eventsInMonth = JSON.parse(JSON.stringify(this.events)).filter(e => {
        e.times = [...e.times].filter(t => moment(t.end).isSameOrAfter(moment(this.dateStartMonth).startOf('weeks'))
            && moment(t.start).isSameOrBefore(moment(this.dateEndMonth).endOf('weeks')));
        return e;
      }).filter(e => e.times.length);
    },
  },
  computed: {
    dateStartMonth() {
      return moment(this.date).startOf('months').format('YYYY-MM-DD');
    },
    dateEndMonth() {
      return moment(this.date).endOf('months').format('YYYY-MM-DD');
    },
    weeksInMonth() {
      let weeks = {};
      for (let i = moment(this.dateStartMonth).startOf('weeks'); moment(i).isSameOrBefore(moment(this.dateEndMonth).endOf('weeks')); i.add(1, 'days')) {
        if (weeks[i.week()]) {
          // update
          weeks[i.week()].number = i.week()
          weeks[i.week()].dates.push(i.format('YYYY-MM-DD'));
        } else {
          // create
          weeks[i.week()] = {
            number: i.week(),
            dates: [i.format('YYYY-MM-DD')],
          };
        }
      }
      return _.orderBy(weeks, i => i.dates);
    },
    daysInMonth() {
      let days = [];
      for (let i = moment(this.dateStartMonth).startOf('weeks'); moment(i).isSameOrBefore(moment(this.dateEndMonth).endOf('weeks')); i.add(1, 'days')) {
        days.push(i.format('YYYY-MM-DD'));
      }
      return days.sort((a, b) => a - b);
    },
    randomColor() {
      return "#" + ((1 << 24) * Math.random() | 0).toString(16);
    },
  },
  watch: {
    events: function () {
      this.calculateEvents();
    }
  },
}
</script>

<style scoped>
:deep(.fc-body) {
  overflow: hidden;
}

:deep(.fc-body__cell) {
  min-height: 160px;
}

:deep(.fc-header *),
:deep(.fc-header__cell *),
:deep(.fc-header__week *),
:deep(.fc-groups *) {
  font-size: 12px;
}

:deep(.fc-header__month),
:deep(.fc-header__week-title),
:deep(.fc-header),
:deep(.fc-header__day),
:deep(.fc-body__cell),
:deep(.fc-groups__title),
  /*:deep(.fc-groups__list),*/
:deep(.fc-header__cell),
:deep(.fc-groups__list-item) {
  border: 1px solid #c5c6c7;
}

:deep(.fc-groups__title) {
  min-height: 114px;
}

:deep(.fc-header__week-title) {
  max-height: 33px;
  overflow: hidden;
}

:deep(.fc-groups__list) {
  /*min-width: 200px;*/
  min-height: 33px;
}

:deep(.fc-groups__list-item) {
  min-height: 47px;
}

:deep(.fc-event) {
  position: absolute;
  cursor: pointer;
  color: white;
}

:deep(.fc-body__cell) {
  transform: translateX(0px);
}

:deep(.fc-body__cell-label) {
  position: absolute;
  right: 3px;
  top: 3px;
}

:deep(.fc-weekend) {
  color: crimson;
  font-weight: bold;
  background-color: cornsilk;
}

:deep(.fc-prev-month),
:deep(.fc-next-month) {
  color: lightgray;
}

:deep(.fc-today) {
  padding: 3px;
  transform: translate(5px, -5px);
  border: 1px solid lightgray;
}
</style>
