<template>
  <div id="calendar" class="p-d-flex p-flex-column fc fc-media-screen fc-direction-ltr fc-theme-standard"
       style="width: 100%">
    <slot class="p-d-inline-flex custom-calendar__header" name="header">
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex">
          <span class="p-buttonset p-d-flex p-flex-row">
            <Button
                class="p-d-inline-flex p-button-success p-button-outlined"
                icon="pi pi-angle-left"
                @click="onClickPrevDate"
            />
            <Button
                :disabled="moment().format('DD.MM.YYYY') === moment(date).format('DD.MM.YYYY')"
                :label="_t('label_Today')"
                class="p-d-inline-flex p-button-success p-button-outlined"
                @click="onClickTodayDate"/>
            <Button
                class="p-d-inline-flex p-button-success p-button-outlined"
                icon="pi pi-angle-right"
                @click="onClickNextDate"
            />
          </span>
        </div>
        <div class="p-d-inline-flex">
          <SelectButton v-model="viewType" :options="viewTypes" class="p-flex-row p-d-flex" optionLabel="name">
            <template #option="slotProps">
              <span class="p-button-label">{{ _t('label_' + slotProps.option.name) }}</span>
            </template>
          </SelectButton>
        </div>
      </div>
    </slot>
    <div
        :class="`${view}-${viewType.code}`"
        class="p-d-inline-flex custom-calendar__content p-mt-2"
    >
      <component
          :is="activeComponent"
          ref="calendarView"
          :date="date"
          :events="events"
          :holidays="holidays"
          @click-day="$emit('clickDay', $event)"
          @click-event="$emit('clickEvent', $event)"
      ></component>
    </div>
    <slot class="p-d-inline-flex custom-calendar__footer" name="footer"></slot>
  </div>
</template>

<script>

import moment from 'moment';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import TimelineDay from './views/timeline-day.view';
import TimelineWeek from './views/timeline-week.view';
import TimelineMonth from './views/timeline-month.view';
import DayGridMonth from './views/daygrid-month.view';
import DayGridYear from './views/daygrid-year.view';
import {
  DayGridDrawMixin,
  eventDrawDayMixin,
  eventDrawMixin
} from '@/components/CustomFullCalendar/mixins/event-draw.mixin';
import {commonMixin} from '@/components/CustomFullCalendar/mixins/common.mixin';

export default {
  name: 'custom-full-calendar',
  mixins: [commonMixin, eventDrawMixin, eventDrawDayMixin, DayGridDrawMixin],
  components: {
    'TimelineDay': TimelineDay,
    'TimelineWeek': TimelineWeek,
    'TimelineMonth': TimelineMonth,
    'DaygridMonth': DayGridMonth,
    'DaygridYear': DayGridYear,
  },
  props: {
    view: {
      type: String,
      default: ViewEnum.TIMELINE,
      validator: val => Object.values(ViewEnum).indexOf(val) !== -1
    },
    type: {
      type: String,
      default: ViewTypeEnum.MONTH,
      validator: val => Object.values(ViewTypeEnum).indexOf(val) !== -1
    },
    viewTypes: {
      required: true,
      type: Array,
      default: Object.values(ViewTypeEnum).map(i => ({name: i.toLowerCase(), code: i})),
    },
  },
  data: () => ({
    viewType: {
      name: ViewTypeEnum.MONTH.toLowerCase(),
      code: ViewTypeEnum.MONTH,
    },
  }),
  created() {
    this.moment = moment;
    this.moment.updateLocale('en', {
      week: {
        dow: 1,
      }
    });
  },
  mounted() {
    this.viewType = {
      name: this.type.toLowerCase(),
      code: this.type,
    };
    try {
      const timer = JSON.parse(localStorage.getItem('calendarDraw')) ?? null;
      Object.keys(timer).map(key => {
        if (timer && timer[key]) clearInterval(parseInt(timer[key]));
      });
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    onClickPrevDate() {
      this.$emit('update:date', this.moment(this.date).subtract(1, this.viewType.code.toLowerCase()));
      this.$refs.calendarView.calculateEvents();
    },
    onClickNextDate() {
      this.$emit('update:date', this.moment(this.date).add(1, this.viewType.code.toLowerCase()));
      this.$refs.calendarView.calculateEvents();
    },
    onClickTodayDate() {
      this.$emit('update:date', this.moment());
      // this.eventsDraw();
      this.$refs.calendarView.calculateEvents();
      // this.$refs.calendarView.eventsDraw();
    },
  },
  computed: {
    activeComponent: function () {
      const componentName = this.view.charAt(0).toUpperCase() + this.view.slice(1).toLowerCase()
          + this.viewType.code.charAt(0).toUpperCase() + this.viewType.code.slice(1).toLowerCase();
      // console.log('Component: ', componentName);
      return componentName;
    },
    viewEnum: () => ViewEnum,
    viewTypeEnum: () => ViewTypeEnum,
  },
  watch: {
    viewType: function () {
      let timer = {};
      try {
        timer = JSON.parse(localStorage.getItem('calendarDraw')) ?? {};
      } catch (e) {
        timer = {};
      }
      const view = this.view.toUpperCase();
      const type = this.viewType.code;
      if (view === ViewEnum.TIMELINE) {
        if (type === this.viewTypeEnum.DAY) {
          if (timer && timer[`${view}_${type}`]) clearInterval(parseInt(timer[`${view}_${type}`]));
          timer = {...timer, [`${view}_${type}`]: setInterval(() => this.eventsDayDraw(), 100)};
        } else {
          if (timer && timer[`${view}_${type}`]) clearInterval(parseInt(timer[`${view}_${type}`]));
          timer = {...timer, [`${view}_${type}`]: setInterval(() => this.eventsDraw(), 100)};
        }
      }
      if (this.view.toUpperCase() === ViewEnum.DAYGRID) {
        if (timer && timer[`${view}_${type}`]) clearInterval(parseInt(timer[`${view}_${type}`]));
        timer = {...timer, [`${view}_${type}`]: setInterval(() => this.dayGridDraw(), 100)};
      }
      localStorage.setItem('calendarDraw', JSON.stringify(timer));
    },
    type: function () {
      this.viewType = {
        name: this.type.toLowerCase(),
        code: this.type,
      };
    },
    'options.dow': function () {
      this.moment.updateLocale('en', {
        week: {
          dow: 1,
        }
      });
    },
  }
}
</script>

<style scoped>
:deep(.fc-header *) {
  font-size: 12px;
}

:deep(.fc-event) {
  box-sizing: border-box;
  border: 1px solid #c5c6c7;
}

::v-deep(.fc-header__cell.fc-holiday) {
  color: #5e5d5d;
}

::v-deep(.fc-holiday) {
  /*background: #f5899d;*/
  background: #989898;
}

::v-deep(.fc-holiday-inactive) {
  background: #eae5e5;
}

::v-deep(.DAYGRID-MONTH .fc-event),
::v-deep(.DAYGRID-YEAR .fc-event) {
  font-size: 0.9rem;
}
</style>
