<template>
  <div class="fc-wrapper p-grid p-nogutter nested-grid" style="width: 100%">
    <div class="p-col-2 fc-groups">
      <div class="p-grid p-nogutter nested-grid p-ai-center vertical-container fc-groups__title">
        <div class="p-col-12 p-text-center p-text-bold">{{ _t('label_Events') }}</div>
      </div>
      <div class="p-grid p-nogutter nested-grid fc-groups__list">
        <div
            v-for="(event, eventIndex) in eventsInWeek"
            :key="eventIndex"
            class="fc-groups__list-item p-col-12 p-p-2 fs-11"
        >
          <router-link :to="'/alarm-plans/'+event.alarmplanId">
            {{ event.title }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="p-col-10 fc-month">
      <div class="p-col-12 fc-header p-p-2 p-text-center p-text-bold">
        {{ this._t('label_week') }} {{ moment(date).format('W, ') }} {{ _t(moment(date).format('MMMM').toUpperCase()) }}
        {{ moment(date).format('YYYY') }}
      </div>
      <div class="p-grid p-nogutter nested-grid" style="width: 100%">
        <div class="p-col-12">
          <div class="fc-header__weeks p-grid nested-grid p-nogutter">
            <div
                v-for="(weekday, weekdayIndex) in daysInWeek"
                :key="weekdayIndex"
                :data-week="moment(weekday).week()"
                :style="{
                  // width: this.weeksInMonth.map(i => i.number).slice(1, this.weeksInMonth.map(i => i.number).length - 1).indexOf(week.number) !== -1 ? '100%' : '',
                }"
                class="fc-header__week p-col p-text-center p-text-bold"
            >
              <div
                  class="p-grid nested-grid p-nogutter"
                  style="width: 100%"
              >
                <div
                    :class="{
                    'fc-today': moment(weekday).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
                    'fc-weekend': [6,7].includes(parseInt(moment(weekday).isoWeekday())),
                    'fc-holiday': holidays.find(i => moment(weekday).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []))
                  }"
                    :data-date="moment(weekday).format('YYYY-MM-DD')"
                    :data-weekday="moment(weekday).format('dd')"
                    class="fc-header__weekday fc-header__cell p-col p-text-center p-py-2 p-text-bold"
                    style="max-height: 33px; overflow: hidden;"
                >
                  <div class="box">{{ moment(weekday).format('DD') }}<br>{{ _t(weekdays[moment(weekday).format('d')]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12 fc-body" style="position: relative;">
          <div
              v-for="(event, eventIndex) in eventsInWeek"
              :key="eventIndex"
              class="fc-days p-grid nested-grid p-nogutter"
              style="width: 100%;"
          >
            <template
                v-for="(day, dayIndex) in daysInWeek"
                :key="dayIndex"
            >
              <div
                  :class="{
                    'fc-current-month': moment(day).month() === moment(date).month(),
                    'fc-prev-month': moment(day).month() < moment(date).month(),
                    'fc-next-month': moment(day).month() > moment(date).month(),
                    'fc-today': moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
                    'fc-weekend': [6,7].includes(parseInt(moment(day).isoWeekday())),
                    'fc-holiday': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []))
                  }"
                  :data-date="moment(day).format('YYYY-MM-DD')"
                  class="fc-body__cell p-col"
              >
                <span
                    style="margin-left: 50%; border-style: dotted; border-width: 1px; border-color: lightgray; width: 1px; height: 100%; display: block;"></span>
              </div>
            </template>
            <template
                v-for="(time, timeIndex) in event.times"
                :key="timeIndex"
            >
              <div
                  v-tooltip.top="`<small><strong>${event.title}${time.holiday ? ' (' + time.holiday.title + ')' : ''}\n</strong>Start: ${moment(time.start).format('YYYY-MM-DD HH:mm')}\nEnd: ${moment(time.end).format('YYYY-MM-DD HH:mm')}</small>`"
                  :data-end="moment(time.end).format('YYYY-MM-DD HH:mm')"
                  :data-id="event.id"
                  :data-start="moment(time.start).format('YYYY-MM-DD HH:mm')"
                  :style="{ backgroundColor: '#689F38' }"
                  class="fc-event p-p-2 p-col"
                  style="max-height: 33px;overflow: hidden;"
                  @click="$emit('clickEvent', $event)"
              >
                <!--              {{ event.title }}-->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
// import * as _ from 'lodash';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {eventDrawMixin} from '@/components/CustomFullCalendar/mixins/event-draw.mixin';
import {commonMixin} from '@/components/CustomFullCalendar/mixins/common.mixin';

export default {
  name: 'timeline-week',
  mixins: [commonMixin, eventDrawMixin],
  data: () => ({
    viewType: ViewTypeEnum.WEEK,
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    eventsInWeek: [],
    weekdays: [
      'label_sunday_short',
      'label_monday_short',
      'label_tuesday_short',
      'label_wednesday_short',
      'label_thursday_short',
      'label_friday_short',
      'label_saturday_short',
    ],
  }),
  created() {
    this.moment = moment;
    this.console = console;
    this.moment.locale('en', {
      week: {
        dow: 1,
      }
    });
    // document.addEventListener('load', this.eventsDraw);
  },
  mounted() {
    this.eventsDraw();
    document.addEventListener('resize', this.eventsDraw);
    this.calculateEvents();
  },
  beforeUnmount() {
    document.removeEventListener('resize', this.eventsDraw);
  },
  unmounted() {
    document.removeEventListener('resize', this.eventsDraw);
  },
  methods: {
    calculateEvents() {
      this.eventsInWeek = JSON.parse(JSON.stringify(this.events)).filter(e => {
        e.times = e.times.filter(t => moment(t.end).isSameOrAfter(moment(this.dateStartWeek).startOf('weeks'))
            && moment(t.start).isSameOrBefore(moment(this.dateEndWeek).endOf('weeks')));
        return e;
      }).filter(e => e.times.length);
    }
  },
  computed: {
    dateStartWeek() {
      return moment(this.date).startOf('weeks').format('YYYY-MM-DD');
    },
    dateEndWeek() {
      return moment(this.date).endOf('weeks').format('YYYY-MM-DD');
    },
    daysInWeek() {
      let days = [];
      for (let i = moment(this.dateStartWeek).startOf('weeks'); moment(i).isSameOrBefore(moment(this.dateEndWeek).endOf('weeks')); i.add(1, 'days')) {
        days.push(i.format('YYYY-MM-DD'));
      }
      return days.sort((a, b) => a + b);
    },
    randomColor() {
      return "#" + ((1 << 24) * Math.random() | 0).toString(16);
    },
  },
  watch: {
    events: function () {
      this.calculateEvents();
    },
    'options.dow': function () {

    },
  }
}
</script>

<style scoped>
:deep(.fc-body) {
  overflow: hidden;
}

:deep(.fc-header *),
:deep(.fc-header__cell *),
:deep(.fc-header__week *),
:deep(.fc-groups *) {
  font-size: 12px;
}

:deep(.fc-header__month),
:deep(.fc-header__week-title),
:deep(.fc-header),
:deep(.fc-header__day),
:deep(.fc-body__cell),
:deep(.fc-groups__title),
  /*:deep(.fc-groups__list),*/
:deep(.fc-header__cell),
:deep(.fc-groups__list-item) {
  border: 1px solid #c5c6c7;
}

:deep(.fc-groups__title) {
  min-height: 82px;
}

:deep(.fc-header__week-title) {
  max-height: 33px;
  overflow: hidden;
}

:deep(.fc-groups__list) {
  /*min-width: 200px;*/
  min-height: 33px;
}

:deep(.fc-groups__list-item) {
  min-height: 47px;
}

:deep(.fc-header__cell),
:deep(.fc-body__cell) {
  min-height: 47px;
}

:deep(.fc-event) {
  position: absolute;
}

:deep(.fc-body__cell) {
  transform: translateX(0px);
}

:deep(.fc-weekend) {
  color: crimson;
  font-weight: bold;
  background-color: cornsilk;
}

:deep(.fc-prev-month),
:deep(.fc-next-month) {
  color: lightgray;
}

.fs-11 {
  font-size: 11px;
}
</style>
